import { render, staticRenderFns } from "./EsCharacteristicsForm.vue?vue&type=template&id=c47d55c0"
import script from "./EsCharacteristicsForm.vue?vue&type=script&lang=ts"
export * from "./EsCharacteristicsForm.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./EsCharacteristicsForm.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IxFormInputQuestion: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/form-input/Question.vue').default,IxTextarea: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/Textarea.vue').default,IxFormInputLargeOptions: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/form-input/LargeOptions.vue').default,SfdrMetricsDialog: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/sfdr/MetricsDialog.vue').default,IxFormInputTextMaster: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/form-input/TextMaster.vue').default,IxTextField: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/TextField.vue').default,IxFormInputSmallOptions: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/form-input/SmallOptions.vue').default,IxSectionText: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/SectionText.vue').default})


/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBadge,VCol,VRow})
