var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.sfdrStrategy)?_c('ix-section-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('ix-form-input-question',{attrs:{"title":_vm.$t('questions.esCharacteristicsDescription.title'),"subtitle":_vm.$t('questions.esCharacteristicsDescription.subtitle'),"ix-id":"esCharacteristicsDescription","tooltip":_vm.esAspectsExamples}}),_vm._v(" "),_c('ix-textarea',{attrs:{"value":_vm.sfdrStrategy.esCharacteristicsDescription},on:{"input":(val) =>
            _vm.triggerUpdateSfdrStrategys('esCharacteristicsDescription', val)}})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12"}},[_c('ix-form-input-question',{attrs:{"title":_vm.$t('questions.esStrategy.title'),"ix-id":"esStrategy","subtitle":_vm.$t('questions.esStrategy.subtitle')}}),_vm._v(" "),_c('ix-form-input-large-options',{attrs:{"options":_vm.options,"value":_vm.sfdrStrategy.esStrategy,"is-multiple":true,"is-editable":true},on:{"input":(val) => _vm.triggerUpdateSfdrStrategys('esStrategy', val)}})],1),_vm._v(" "),(_vm.sfdrStrategy.esStrategy)?_c('div',[(_vm.sfdrStrategy.esStrategy.includes('ESG_INTEGRATION'))?_c('v-col',{attrs:{"cols":"12"}},[_c('ix-form-input-question',{attrs:{"title":_vm.$t('questions.esgIntegrationDescription.title'),"ix-id":"esgIntegrationDescription"}}),_vm._v(" "),_c('ix-textarea',{attrs:{"value":_vm.sfdrStrategy.esgIntegrationDescription},on:{"input":(val) =>
              _vm.triggerUpdateSfdrStrategys('esgIntegrationDescription', val)}})],1):_vm._e(),_vm._v(" "),(_vm.sfdrStrategy.esStrategy.includes('EXCLUSION_CRITERIA'))?_c('v-col',{attrs:{"cols":"12"}},[_c('ix-form-input-question',{attrs:{"ix-id":"exclusionCriteriaDescription","title":_vm.$t('questions.exclusionCriteriaDescription.title')}}),_vm._v(" "),_c('ix-textarea',{attrs:{"value":_vm.sfdrStrategy.exclusionCriteriaDescription},on:{"input":(val) =>
              _vm.triggerUpdateSfdrStrategys('exclusionCriteriaDescription', val)}})],1):_vm._e(),_vm._v(" "),(_vm.sfdrStrategy.esStrategy.includes('BEST_IN_CLASS'))?_c('v-col',{attrs:{"cols":"12"}},[_c('ix-form-input-question',{attrs:{"ix-id":"bestInClassDescription","title":_vm.$t('questions.bestInClassDescription.title')}}),_vm._v(" "),_c('ix-textarea',{attrs:{"value":_vm.sfdrStrategy.bestInClassDescription},on:{"input":(val) => _vm.triggerUpdateSfdrStrategys('bestInClassDescription', val)}})],1):_vm._e(),_vm._v(" "),(_vm.sfdrStrategy.esStrategy.includes('NORM_BASED_SCREENING'))?_c('v-col',{attrs:{"cols":"12"}},[_c('ix-form-input-question',{attrs:{"ix-id":"normBasedScreeningDescription","title":_vm.$t('questions.normBasedScreeningDescription.title')}}),_vm._v(" "),_c('ix-textarea',{attrs:{"value":_vm.sfdrStrategy.normBasedScreeningDescription},on:{"input":(val) =>
              _vm.triggerUpdateSfdrStrategys('normBasedScreeningDescription', val)}})],1):_vm._e(),_vm._v(" "),(_vm.sfdrStrategy.esStrategy.includes('SUSTAINABILITY_LABEL'))?_c('v-col',{attrs:{"cols":"12"}},[_c('ix-form-input-question',{attrs:{"ix-id":"sustainabilityLabelDescription","title":_vm.$t('questions.sustainabilityLabelDescription.title')}}),_vm._v(" "),_c('ix-textarea',{attrs:{"value":_vm.sfdrStrategy.sustainabilityLabelDescription},on:{"input":(val) =>
              _vm.triggerUpdateSfdrStrategys(
                'sustainabilityLabelDescription',
                val,
              )}})],1):_vm._e(),_vm._v(" "),_c('v-col',{attrs:{"cols":"12"}},[_c('ix-form-input-question',{attrs:{"title":_vm.$t('questions.esCharacteristicsPromoted.title'),"ix-id":"esCharacteristicsPromoted"}}),_vm._v(" "),_c('ix-form-input-large-options',{attrs:{"options":_vm.optionsEsCharacteristicsPromoted,"value":_vm.fund.esCharacteristicsPromoted,"is-multiple":false,"is-editable":true},on:{"input":(val) => _vm.triggerUpdateFund('esCharacteristicsPromoted', val)}})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12"}},[_c('ix-form-input-question',{attrs:{"ix-id":"actionsTaken","title":_vm.$t('questions.actionsTaken.title'),"subtitle":_vm.$t('questions.actionsTaken.subtitle'),"is-mandatory":false}}),_vm._v(" "),_c('ix-textarea',{attrs:{"value":_vm.sfdrStrategy.actionsTaken},on:{"input":(val) => _vm.triggerUpdateSfdrStrategys('actionsTaken', val)}})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12"}},[(_vm.showDebugging)?_c('h4',{staticStyle:{"color":"red"}},[_vm._v("\n          metricIds\n          "),_c('v-badge',{directives:[{name:"badge",rawName:"v-badge"}],attrs:{"bordered":"","color":"error","icon":"mdi-lock","overlap":""}})],1):_vm._e(),_vm._v(" "),_c('sfdr-metrics-dialog',{attrs:{"title":_vm.$t('questions.metricIds.title'),"subtitle":_vm.indicatorDescription,"tooltip":_vm.sustainabilityIndictatorDescription,"portfolio-id":_vm.portfolioId,"query":{
            sections: [
              117, // 'IRIS Indicators',
              82, // 'SFDR: PAI Indicators',
              967, // 'ImpactNexus Indicators',
              998, // 'EDCI Metrics',
              1101, // 'KfW'
            ],
          },"value":_vm.sfdrStrategy.metrics},on:{"input":(val) => _vm.triggerUpdateSfdrStrategys('metricIds', val)}})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12"}},[_c('ix-form-input-text-master',{attrs:{"title":_vm.$t('questions.isConsideringSourcesAndEstimates.title'),"subtitle":_vm.$t('questions.isConsideringSourcesAndEstimates.subtitle'),"tooltip":_vm.$t('questions.isConsideringSourcesAndEstimates.tooltip'),"is-mandatory":true,"value":_vm.sfdrStrategy.isConsideringSourcesAndEstimates,"ix-id":"isConsideringSourcesAndEstimates","use-text-field":false,"use-binary":""},on:{"input":(val) =>
              _vm.triggerUpdateSfdrStrategys(
                'isConsideringSourcesAndEstimates',
                val,
              )}})],1),_vm._v(" "),(_vm.sfdrStrategy.isConsideringSourcesAndEstimates)?_c('v-col',{attrs:{"cols":"12"}},[_c('ix-form-input-question',{attrs:{"title":_vm.$t('questions.dataSourcesConsidered.title'),"ix-id":"dataSourcesConsidered","is-mandatory":true}}),_vm._v(" "),_c('ix-text-field',{attrs:{"value":_vm.sfdrStrategy.dataSourcesConsidered},on:{"input":(val) => _vm.triggerUpdateSfdrStrategys('dataSourcesConsidered', val)}})],1):_vm._e(),_vm._v(" "),(_vm.sfdrStrategy.isConsideringSourcesAndEstimates)?_c('v-col',{attrs:{"cols":"12"}},[_c('ix-form-input-question',{attrs:{"title":_vm.$t('questions.shareOfDataSecondHand'),"ix-id":"shareOfDataSecondHand","is-mandatory":true}}),_vm._v(" "),_c('ix-text-field',{attrs:{"value":_vm.sfdrStrategy.shareOfDataSecondHand},on:{"input":(val) => _vm.triggerUpdateSfdrStrategys('shareOfDataSecondHand', val)}})],1):_vm._e(),_vm._v(" "),_c('v-col',{attrs:{"cols":"12"}},[_c('ix-form-input-question',{attrs:{"title":_vm.$t('questions.sustainabilityIndicatorsMonitoringStrategy'),"ix-id":"sustainabilityIndicatorsMonitoringStrategy","is-mandatory":true}}),_vm._v(" "),_c('ix-textarea',{attrs:{"value":_vm.sfdrStrategy.sustainabilityIndicatorsMonitoringStrategy},on:{"input":(val) =>
              _vm.triggerUpdateSfdrStrategys(
                'sustainabilityIndicatorsMonitoringStrategy',
                val,
              )}})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12"}},[_c('ix-form-input-question',{attrs:{"title":_vm.$t('questions.entityResponsibleForMonitoringIndicators'),"ix-id":"entityResponsibleForMonitoringIndicators","is-mandatory":true}}),_vm._v(" "),_c('ix-textarea',{attrs:{"value":_vm.sfdrStrategy.entityResponsibleForMonitoringIndicators},on:{"input":(val) =>
              _vm.triggerUpdateSfdrStrategys(
                'entityResponsibleForMonitoringIndicators',
                val,
              )}})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12"}},[_c('ix-form-input-question',{attrs:{"title":_vm.$t('questions.investmentStrategy.title'),"subtitle":_vm.$t('questions.investmentStrategy.subtitle'),"ix-id":"investmentStrategy","tooltip":_vm.$t('questions.investmentStrategy.tooltip'),"is-mandatory":true}}),_vm._v(" "),_c('ix-textarea',{attrs:{"value":_vm.fund.investmentStrategy},on:{"input":(val) => _vm.triggerUpdateFund('investmentStrategy', val)}})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12"}},[_c('ix-form-input-question',{attrs:{"title":_vm.$t(
              'questions.investmentStrategy.bindingElementsOfInvestmentStrategy.title',
            ),"ix-id":"bindingElementsOfInvestmentStrategy","is-mandatory":true}}),_vm._v(" "),_c('ix-textarea',{attrs:{"value":_vm.fund.bindingElementsOfInvestmentStrategy},on:{"input":(val) =>
              _vm.triggerUpdateFund('bindingElementsOfInvestmentStrategy', val)}})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12"}},[_c('ix-form-input-question',{attrs:{"title":_vm.$t('questions.investmentStrategy.minimumRateToReduceScope.title'),"ix-id":"minimumRateToReduceScope","subtitle":_vm.$t(
              'questions.investmentStrategy.minimumRateToReduceScope.subtitle',
            ),"is-mandatory":true}}),_vm._v(" "),_c('ix-textarea',{attrs:{"value":_vm.fund.minimumRateToReduceScope},on:{"input":(val) => _vm.triggerUpdateFund('minimumRateToReduceScope', val)}})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12"}},[_c('ix-form-input-question',{attrs:{"title":_vm.$t('questions.investmentStrategy.companyGovernancePolicy.title'),"ix-id":"companyGovernancePolicy","subtitle":_vm.$t(
              'questions.investmentStrategy.companyGovernancePolicy.subtitle',
            ),"tooltip":_vm.$t('questions.investmentStrategy.companyGovernancePolicy.tooltip'),"is-mandatory":true}}),_vm._v(" "),_c('ix-textarea',{attrs:{"value":_vm.fund.companyGovernancePolicy},on:{"input":(val) => _vm.triggerUpdateFund('companyGovernancePolicy', val)}})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12"}},[_c('ix-form-input-question',{attrs:{"title":_vm.$t('questions.dueDiligenceProcessDescription.title'),"ix-id":"dueDiligenceProcessDescription","tooltip":_vm.$t('questions.dueDiligenceProcessDescription.tooltip'),"is-mandatory":true}}),_vm._v(" "),_c('ix-textarea',{attrs:{"value":_vm.sfdrStrategy.dueDiligenceProcessDescription},on:{"input":(val) =>
              _vm.triggerUpdateSfdrStrategys(
                'dueDiligenceProcessDescription',
                val,
              )}})],1),_vm._v(" "),_c('v-col',{attrs:{"col":"12"}},[_c('ix-form-input-question',{attrs:{"title":_vm.$t(
              'questions.engagementPolicyWithSpecificInvestmentStrategy.title',
            ),"ix-id":"engagementPolicyWithSpecificInvestmentStrategy","tooltip":_vm.$t(
              'questions.engagementPolicyWithSpecificInvestmentStrategy.tooltip',
            )}}),_vm._v(" "),_c('ix-textarea',{attrs:{"value":_vm.sfdrStrategy.engagementPolicyWithSpecificInvestmentStrategy},on:{"input":(val) =>
              _vm.triggerUpdateSfdrStrategys(
                'engagementPolicyWithSpecificInvestmentStrategy',
                val,
              )}})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12"}},[_c('ix-form-input-question',{attrs:{"title":_vm.$t('questions.investmentStrategy.assetAllocation.title'),"subtitle":_vm.$t('questions.investmentStrategy.assetAllocation.subtitle'),"ix-id":"assetAllocation","tooltip":_vm.$t('questions.investmentStrategy.assetAllocation.tooltip'),"is-mandatory":true}}),_vm._v(" "),_c('ix-textarea',{attrs:{"value":_vm.fund.assetAllocation},on:{"input":(val) => _vm.triggerUpdateFund('assetAllocation', val)}})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12"}},[_c('ix-form-input-question',{attrs:{"title":_vm.$t('questions.benchmarkDescription.title'),"ix-id":"benchmarkDescription","subtitle":_vm.$t('questions.benchmarkDescription.subtitle'),"is-mandatory":false,"tooltip":_vm.benchmarkInfo}}),_vm._v(" "),_c('ix-form-input-small-options',{attrs:{"options":[
            { value: true, label: 'Yes' },
            { value: false, label: 'No' },
          ],"value":_vm.sfdrStrategy.isIndexDesignated,"multiple":false,"item-key":"value","item-value":"value","item-text":"label"},on:{"input":(val) => _vm.triggerUpdateSfdrStrategys('isIndexDesignated', val)}})],1),_vm._v(" "),(_vm.sfdrStrategy.isIndexDesignated)?_c('v-col',{attrs:{"cols":"12"}},[_c('ix-form-input-question',{attrs:{"title":_vm.$t('questions.sustainablyAlignedIndexExplanation.title'),"ix-id":"sustainablyAlignedIndexExplanation","is-mandatory":false}}),_vm._v(" "),_c('ix-textarea',{attrs:{"value":_vm.sfdrStrategy.sustainablyAlignedIndexExplanation},on:{"input":(val) =>
              _vm.triggerUpdateSfdrStrategys(
                'sustainablyAlignedIndexExplanation',
                val,
              )}})],1):_vm._e(),_vm._v(" "),(_vm.sfdrStrategy.isIndexDesignated)?_c('v-col',{attrs:{"cols":"12"}},[_c('ix-form-input-question',{attrs:{"title":_vm.$t('questions.investmentAlignedIndexExplanation.title'),"ix-id":"investmentAlignedIndexExplanation","is-mandatory":false}}),_vm._v(" "),_c('ix-textarea',{attrs:{"value":_vm.sfdrStrategy.investmentAlignedIndexExplanation},on:{"input":(val) =>
              _vm.triggerUpdateSfdrStrategys(
                'investmentAlignedIndexExplanation',
                val,
              )}})],1):_vm._e(),_vm._v(" "),(_vm.sfdrStrategy.isIndexDesignated)?_c('v-col',{attrs:{"cols":"12"}},[_c('ix-form-input-question',{attrs:{"title":_vm.$t('questions.broadMarketIndexComparison.title'),"ix-id":"broadMarketIndexComparison","is-mandatory":false}}),_vm._v(" "),_c('ix-textarea',{attrs:{"value":_vm.sfdrStrategy.broadMarketIndexComparison},on:{"input":(val) =>
              _vm.triggerUpdateSfdrStrategys('broadMarketIndexComparison', val)}})],1):_vm._e(),_vm._v(" "),(_vm.sfdrStrategy.isIndexDesignated)?_c('v-col',{attrs:{"cols":"12"}},[_c('ix-form-input-question',{attrs:{"title":_vm.$t('questions.indexCalculationMethodology.title'),"ix-id":"indexCalculationMethodology","is-mandatory":false}}),_vm._v(" "),_c('ix-textarea',{attrs:{"value":_vm.sfdrStrategy.indexCalculationMethodology},on:{"input":(val) =>
              _vm.triggerUpdateSfdrStrategys('indexCalculationMethodology', val)}})],1):_vm._e()],1):_vm._e()],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }