import "core-js/modules/es6.number.constructor.js";
import "core-js/modules/es6.string.includes.js";
import "core-js/modules/es7.array.includes.js";
import "core-js/modules/es6.array.map.js";
import "core-js/modules/es7.object.entries.js";
import { esCharacteristicMapping, esCharacteristicPromotedMapping, sustainabilityIndictatorDescription } from '~/functions/sfdr';
import { useUpdateFundMutation, useUpdateSfdrStrategyMutation, useGetUserQuery } from '~/types/types';
export default defineComponent({
  props: {
    sfdrStrategy: {
      type: Object,
      required: true
    },
    fundId: {
      type: Number,
      required: true
    },
    portfolioId: {
      type: String,
      required: true
    },
    fund: {
      type: Object,
      required: true
    }
  },
  setup: function setup(props) {
    var _useNuxtApp = useNuxtApp(),
      $i18n = _useNuxtApp.$i18n;
    var _useUpdateSfdrStrateg = useUpdateSfdrStrategyMutation(),
      updateSfdrStrategy = _useUpdateSfdrStrateg.mutate;
    var _useUpdateFundMutatio = useUpdateFundMutation(),
      updateFund = _useUpdateFundMutatio.mutate;
    var triggerUpdateFund = function triggerUpdateFund(key, val) {
      var input = {
        id: props.fundId
      };
      input[key] = val === undefined || val === '' ? null : val;
      updateFund({
        input: input
      });
    };
    var _useGetUserQuery = useGetUserQuery(),
      userResult = _useGetUserQuery.result;
    var showDebugging = computed(function () {
      try {
        var _userResult$value, _userResult$value$get, _JSON$parse, _userResult$value2, _userResult$value2$ge;
        return ((_userResult$value = userResult.value) === null || _userResult$value === void 0 ? void 0 : (_userResult$value$get = _userResult$value.getUser) === null || _userResult$value$get === void 0 ? void 0 : _userResult$value$get.isImpersonated) && ((_JSON$parse = JSON.parse(localStorage.getItem('debugging'))) === null || _JSON$parse === void 0 ? void 0 : _JSON$parse.includes('debugging')) || ((_userResult$value2 = userResult.value) === null || _userResult$value2 === void 0 ? void 0 : (_userResult$value2$ge = _userResult$value2.getUser) === null || _userResult$value2$ge === void 0 ? void 0 : _userResult$value2$ge.isAdmin);
      } catch (_unused) {}
    });

    // FIXME this part is not very pretty. But put them into JSON won't allow us to have beautiful formatting so it's a tradeoff
    var esAspectsExamples = "## Examples of social and environmental characteristics\n### Environmental\n- Climate mitigation\n- Protection of biodiversity\n- Adjustment to climate change\n- The sustainable use and protection of water and maritime resources\n- The transition to a circular economy, the avoidance of waste, and recycling\n- The avoidance and reduction of environmental pollution\n- The protection of healthy ecosystems\n- Sustainable land use\n\n### Social\n- Compliance with recognised labour standards 19 (no child labour, forced labour or discrimination)\n- Compliance with employment safety and health protection\n- Appropriate remuneration, fair working conditions, diversity, and training and development opportunities\n- Trade union rights and freedom of assembly\n- Guarantee of adequate product safety, including health protection\n- Application of the same requirements to entities in the supply chain\n- Inclusive projects and consideration of the interests of communities and social minorities.";
    var indicatorDescription = "\n- In this step, you can select **sustainability indicators** for the environmental/social characteristics that are promoted by your fund.\n- Sustainability indicators serve to measure the sustainability performance of your fund (mandatory for article 9, 8, 8+ funds)\n- You can search for predefined indicators in the ImpactNexus metrics database or define custom indicators.";
    var benchmarkInfo = "\nFor Article 8 funds that promote E/S characteristics reference to an index is a\xA0**voluntary decision**.\n\nIf deciding to refer to an index, please note, that there are currently no trackable market indexes (e.g., developed by MSCI or S&P) that can be used by VC and Private Equity investors as reference benchmarks. The existing indexes are most often comprised of listed equity and fixed-income assets.\n\nA newly introduced [\u201CESG Transparency Private Equity and Venture Capital Index\u201D](https://static1.squarespace.com/static/60bdfcccc5e6be6d29a445b4/t/622ba06466e0b151c38919ac/1647026292006/Orbis_Report_2022.pdf) was constructed by ITPEnergised and [Orbis Advisory](https://www.orbisadvisory.com/) and analyses 155 Private Equity (PE), and for the first time, 122 Venture Capital (VC) firms\u2019 ESG reporting performance. The assessment uses 73 criteria centred around two themes: Responsible Investing and In-house ESG (incl. sustainability KPIs). *Hence, if you decided to adopt this index as a reference benchmark to meet the environmental or social characteristics, you should make sure that the financial product\xA0meets the characteristics of the companies constituting the index by complying with the benchmark's themes.*\n\nHowever, it is not possible to see the life performance of companies constituting the benchmark without becoming a member. PE and VC investors could contact the issuer to learn more about the possibilities to monitor, track, and compare their ESG performance in accordance with this benchmark.";
    if (($i18n === null || $i18n === void 0 ? void 0 : $i18n.locale) === 'de') {
      esAspectsExamples = "## Beispiele f\xFCr soziale und \xF6kologische Merkmale\n### Umweltfreundlich\n- Klimaschutz\n- Schutz der Artenvielfalt\n- Anpassung an den Klimawandel\n- Die nachhaltige Nutzung und der Schutz von Wasser und Meeresressourcen\n- Der \xDCbergang zu einer Kreislaufwirtschaft, die Vermeidung von Abf\xE4llen und Recycling\n- Die Vermeidung und Reduzierung von Umweltbelastungen\n- Der Schutz gesunder \xD6kosysteme\n- Nachhaltige Landnutzung\n\n### Sozial\n- Einhaltung anerkannter Arbeitsnormen19 (keine Kinderarbeit, Zwangsarbeit oder Diskriminierung)\n- Einhaltung der Arbeitssicherheit und des Gesundheitsschutzes\n- Angemessene Verg\xFCtung, faire Arbeitsbedingungen, Vielfalt sowie Aus- und Weiterbildungsm\xF6glichkeiten\n- Gewerkschaftsrechte und Versammlungsfreiheit\n- Gew\xE4hrleistung einer ausreichenden Produktsicherheit, einschlie\xDFlich Gesundheitsschutz\n- Anwendung der gleichen Anforderungen auf Unternehmen in der Lieferkette\n- Inklusive Projekte und Ber\xFCcksichtigung der Interessen von Gemeinschaften und gesellschaftlichen Minderheiten.";
      indicatorDescription = "\n- In diesem Schritt k\xF6nnen Sie **Nachhaltigkeitsindikatoren** f\xFCr die von Ihrem Fonds gef\xF6rderten \xF6kologischen/sozialen Merkmale ausw\xE4hlen.\n- Nachhaltigkeitsindikatoren dienen zur Messung der Nachhaltigkeitsleistung Ihres Fonds (obligatorisch f\xFCr Artikel 9, 8, 8+ Fonds)\n- Sie k\xF6nnen in der ImpactNexus-Metrikdatenbank nach vordefinierten Indikatoren suchen oder benutzerdefinierte Indikatoren definieren.";
      benchmarkInfo = "F\xFCr Artikel-8-Fonds, die E/S-Merkmale f\xF6rdern, ist die Bezugnahme auf einen Index eine\xA0**freiwillige Entscheidung**.\n\nWenn Sie sich f\xFCr einen Index entscheiden, beachten Sie bitte, dass es derzeit keine nachverfolgbaren Marktindizes (z. B. entwickelt von MSCI oder S&P) gibt, die von VC- und Private-Equity-Investoren als Referenzbenchmarks verwendet werden k\xF6nnen. Die bestehenden Indizes bestehen meist aus b\xF6rsennotierten Aktien und festverzinslichen Verm\xF6genswerten.\n\nEin neu eingef\xFChrter [\u201EESG Transparency Private Equity and Venture Capital Index\u201C](https://static1.squarespace.com/static/60bdfcccc5e6be6d29a445b4/t/622ba06466e0b151c38919ac/1647026292006/Orbis_Report_2022.pdf) wurde von ITPEnergised und [Orbis Advisory]( https://www.orbisadvisory.com/) und analysiert die ESG-Berichtsleistung von 155 Private Equity (PE)- und erstmals 122 Venture Capital (VC)-Unternehmen. Die Bewertung basiert auf 73 Kriterien, die sich auf zwei Themen konzentrieren: Responsible Investing und Inhouse ESG (inkl. Nachhaltigkeits-KPIs). *Wenn Sie sich daher entschieden haben, diesen Index als Referenzbenchmark zu \xFCbernehmen, um den \xF6kologischen oder sozialen Merkmalen gerecht zu werden, sollten Sie sicherstellen, dass das Finanzprodukt den Merkmalen der Unternehmen entspricht, aus denen sich der Index zusammensetzt, indem es mit den Themen der Benchmark \xFCbereinstimmt.*\n\nAllerdings ist es ohne Mitgliedschaft nicht m\xF6glich, die Lebensleistung von Unternehmen zu sehen, die den Ma\xDFstab bilden. PE- und VC-Investoren k\xF6nnen den Emittenten kontaktieren, um mehr \xFCber die M\xF6glichkeiten zu erfahren, ihre ESG-Leistung gem\xE4\xDF dieser Benchmark zu \xFCberwachen, zu verfolgen und zu vergleichen.";
    }
    var triggerUpdateSfdrStrategys = function triggerUpdateSfdrStrategys(key, val) {
      var input = {
        id: props.sfdrStrategy.id
      };
      input[key] = val === undefined || val === null ? '' : val;
      updateSfdrStrategy({
        input: input
      });
    };
    return {
      triggerUpdateSfdrStrategys: triggerUpdateSfdrStrategys,
      updateSfdrStrategy: updateSfdrStrategy,
      esAspectsExamples: esAspectsExamples,
      indicatorDescription: indicatorDescription,
      benchmarkInfo: benchmarkInfo,
      triggerUpdateFund: triggerUpdateFund,
      showDebugging: showDebugging
    };
  },
  data: function data() {
    return {
      options: Object.entries(esCharacteristicMapping).map(function (e) {
        var _e$;
        return {
          id: e[0],
          label: e[1].label,
          sublabel: (_e$ = e[1]) === null || _e$ === void 0 ? void 0 : _e$.sublabel,
          tooltip: e[1].info
        };
      }),
      optionsEsCharacteristicsPromoted: Object.entries(esCharacteristicPromotedMapping).map(function (e) {
        var _e$2;
        return {
          id: e[0],
          label: e[1].label,
          sublabel: (_e$2 = e[1]) === null || _e$2 === void 0 ? void 0 : _e$2.sublabel,
          tooltip: e[1].info
        };
      }),
      sustainabilityIndictatorDescription: sustainabilityIndictatorDescription
    };
  }
});